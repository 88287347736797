import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap'
import ApiData from '../components/apiData'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../components/style.css'
import Layout from '../components/layout'
import Image from '../components/image'
const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta http-equiv="refresh" content="30" />
    </Helmet>
    <Container fluid className="scoreBoard">
      <ApiData />
    </Container>
  </Layout>
)

export default IndexPage
