import React from 'react'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'

export default class TeamList extends React.Component {
  state = {
    teams: []
  }

  componentDidMount() {
    axios.get(`https://finals.2020.hackasat.com/scoreboard.json`).then(res => {
      const teams = res.data.scoreboard
      const masked = res.data.is_masked
      const timeStamp = res.data.generated_at
      this.setState({ teams, masked, timeStamp })
    })
  }

  render() {
    function getTimeStamp() {
      return { __html: new Date() }
    }

    function score(e, id) {
      if (e) {
        if (e.challenge_id == 1) {
          return (e.sum / 50) * 100
        } else if (e.challenge_id == 6) {
          return (e.sum / 100) * 100
        } else {
          return (e.sum / 200) * 100
        }
      } else {
        return 0
      }
    }

    if (this.state.masked) {
      return (
        <div className="hiddenWrapper">
          <img src="/hidden-text.svg" className="hiddenImage" />
        </div>
      )
    } else {
      return (
        <>
          <div>
            <Row className="titles">
              <Col xs={1}>Team</Col>
              <Col xs={1}>Score</Col>
              <Col>Challenges</Col>
              <Col xs={2}>
                On-Orbit
                <br />
                Status
              </Col>
            </Row>
            <Row className="phaseIcons">
              <Col xs={1}></Col>
              <Col xs={1}></Col>
              <Col>
                <img src="/icons/computer.svg" />
              </Col>
              <Col>
                <img src="/icons/sat.svg" />
              </Col>
              <Col>
                <img src="/icons/location.svg" />
              </Col>
              <Col>
                <img src="/icons/bio.svg" />
              </Col>
              <Col>
                <img src="/icons/camera.svg" />
              </Col>
              <Col>
                <img src="/icons/moon.svg" />
              </Col>
              <Col xs={2}></Col>
            </Row>
            {this.state.teams.map((team, index) => {
              return (
                <Row key={index} className={'team' + team.id}>
                  <Col xs={1} key={team.id + 'name'} className="teamIcon ">
                    <img src={'/icons/team' + team.id + '.svg'} />
                    <span>{team.name}</span>
                  </Col>

                  <Col
                    xs={1}
                    key={team.id + '_total'}
                    className="blkBottom teamScore"
                  >
                    {team.total}
                  </Col>
                  {[...Array(6)].map((e, i) => (
                    <Col
                      className="scoreTicks blkBottom"
                      key={team.id + 'score' + i}
                    >
                      <div>
                        <div
                          style={{ width: score(team.challenges[i]) + '%' }}
                        ></div>
                      </div>
                    </Col>
                  ))}
                  <Col
                    xs={2}
                    className={`float-right blkBottom status-${team.acceptance}`}
                    key={team.id + '_acceptance'}
                  >
                    {team.acceptance}
                  </Col>
                </Row>
              )
            })}
          </div>
          <div className="dateTimeWrapper">
            <p>UPDATED</p>
            <div className="dateTime">{this.state.timeStamp}</div>
          </div>
        </>
      )
    }
  }
}
